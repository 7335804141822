"use client";

import { JSX, useState, useEffect, useRef } from "react";
import Image from "next/image";
import { StaticImageData } from "next/image";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";
import { Play, Pause, Plus, Minus, Star } from "lucide-react";
import config from "@/config";

// Reference type definitions
interface RefType {
  id: string;
}

const refTypes: Record<string, RefType> = {
  creator1: { id: "creator1" },
  creator2: { id: "creator2" },
  creator3: { id: "creator3" },
  creator4: { id: "creator4" },
  creator5: { id: "creator5" },
  creator6: { id: "creator6" },
  creator7: { id: "creator7" },
  creator8: { id: "creator8" }
};

// Types
interface TestimonialStats {
  subscribers?: string;
  monthlyViews?: string;
  averageWatch?: string;
  followers?: string;
  engagement?: string;
  growth?: string;
  clientGrowth?: string;
  productivity?: string;
  roi?: string;
}

interface Testimonial {
  name: string;
  role: string;
  text: string;
  type: RefType;
  img?: string;
}

// Updated testimonials with more specific success stories
const list: Testimonial[] = [
  {
    name: "Sarah Chen",
    role: "Educational Content Creator",
    text: "AriaFlow's AI script generator and stock footage integration helped me create my first educational video series. The automated workflow is incredibly intuitive!",
    type: refTypes.creator1,
    img: "/testimonials/sarah.jpg"
  },
  {
    name: "田中 美咲",
    role: "コンテンツクリエイター",
    text: "AriaFlowのAIスクリプト生成機能と素材ライブラリを使って、教育コンテンツの制作が驚くほど簡単になりました。効率的な制作フローのおかげで、より多くの価値あるコンテンツを提供できています。",
    type: refTypes.creator2
  },
  {
    name: "김지원",
    role: "크리에이티브 디렉터",
    text: "AriaFlow의 AI 기술 덕분에 콘텐츠 제작 시간이 크게 단축되었습니다. 특히 자동화된 편집 기능은 업무 효율성을 획기적으로 개선했습니다.",
    type: refTypes.creator3
  },
  {
    name: "Sophie Dubois",
    role: "Créatrice de Contenu Digital",
    text: "AriaFlow a révolutionné ma façon de créer du contenu. L'IA m'aide à produire des vidéos de qualité en un temps record, me permettant de me concentrer sur la créativité.",
    type: refTypes.creator4
  },
  {
    name: "王小明",
    role: "数字内容创作者",
    text: "AriaFlow的AI辅助功能让视频制作变得轻而易举。智能模板和自动化工具帮助我更专注于创意内容的构思。",
    type: refTypes.creator5
  },
  {
    name: "Mike Rodriguez",
    role: "Finance Content Creator",
    text: "The AI helps me break down complex financial topics into engaging short-form videos. AriaFlow's templates and automation save me hours of work!",
    type: refTypes.creator6
  },
  {
    name: "Hans Mueller",
    role: "Digitaler Content-Ersteller",
    text: "AriaFlow hat meine Content-Erstellung komplett verändert. Die KI-gestützten Tools ermöglichen es mir, professionelle Videos in Rekordzeit zu erstellen und dabei kreativ zu bleiben.",
    type: refTypes.creator7
  },
  {
    name: "佐藤 健一",
    role: "映像クリエイター",
    text: "AriaFlowは私の動画制作ワークフローを一新してくれました。AIによる編集支援のおかげで、より創造的な作業に時間を費やすことができるようになりました。",
    type: refTypes.creator8
  }
];

const StatCard = ({ label, value }: { label: string; value: string }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="flex flex-col items-center p-4 bg-base-200/50 rounded-xl backdrop-blur-sm"
  >
    <span className="text-3xl font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
      {value}
    </span>
    <span className="text-sm text-base-content/70 mt-1">{label}</span>
  </motion.div>
);

const TestimonialCard = ({ testimonial, featured = false }: { testimonial: Testimonial; featured?: boolean }) => {
  const cardClass = featured
    ? "col-span-2 p-8 bg-gradient-to-br from-primary/10 via-secondary/5 to-base-100 backdrop-blur-xl"
    : "p-6 bg-base-100/90 backdrop-blur-lg";

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      className={`rounded-2xl border border-base-content/5 shadow-xl ${cardClass}`}
    >
      <div className="flex flex-col h-full">
        <blockquote className="flex-grow">
          <p className={`${featured ? 'text-lg' : 'text-base'} text-base-content/90 italic`}>
            {testimonial.text}
          </p>
        </blockquote>

        <div className="flex items-center justify-between mt-6 pt-6 border-t border-base-content/10">
          <div className="flex items-center gap-4">
            <div className="relative">
              {testimonial.img ? (
                <Image
                  src={testimonial.img}
                  alt={testimonial.name}
                  width={48}
                  height={48}
                  className="rounded-full object-cover"
                />
              ) : (
                <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center">
                  <span className="text-lg font-semibold text-primary">
                    {testimonial.name[0]}
                  </span>
                </div>
              )}
              {featured && (
                <div className="absolute -top-1 -right-1 bg-primary rounded-full p-1">
                  <Star className="w-3 h-3 text-white" fill="white" />
                </div>
              )}
            </div>
            <div>
              <div className="font-semibold text-base-content">{testimonial.name}</div>
              <div className="text-sm text-base-content/70">{testimonial.role}</div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const TestimonialsAriaFlow11 = () => {
  const t = useTranslations('Testimonials');

  return (
    <section className="relative py-24 overflow-hidden">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-gradient-to-b from-base-200 via-base-100 to-base-200">
        <div className="absolute inset-0 bg-grid-white/10 bg-grid-16 [mask-image:radial-gradient(white,transparent_70%)]" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-20">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl sm:text-5xl font-extrabold mb-4"
          >
            {t('title')}
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-base-content/70 max-w-3xl mx-auto"
          >
            {t('subtitle')}
          </motion.p>

          {/* Stats Section */}
          <div className="grid grid-cols-3 gap-8 max-w-3xl mx-auto mt-16">
            <StatCard label={t('statsLabels.creators')} value={t('stats.creators')} />
            <StatCard label={t('statsLabels.videos')} value={t('stats.videos')} />
            <StatCard label={t('statsLabels.views')} value={t('stats.views')} />
          </div>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {list.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonial={testimonial}
              featured={index === 0}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsAriaFlow11;
