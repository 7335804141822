"use client";

import Image from "next/image";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import { ArrowRight, Sparkles, Star } from "lucide-react";
import config from "@/config";

const CTA = () => {
  const t = useTranslations('CTA');

  return (
    <section className="relative hero overflow-hidden min-h-[80vh]">
      {/* Animated background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary/20 via-base-100/50 to-secondary/20 animate-gradient-xy"></div>
      
      {/* Floating elements */}
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="absolute top-20 left-1/4 text-primary/30"
      >
        <Sparkles className="w-12 h-12" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="absolute bottom-32 right-1/4 text-secondary/30"
      >
        <Star className="w-16 h-16" />
      </motion.div>

      {/* Content */}
      <div className="relative hero-content text-center p-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="flex flex-col items-center max-w-3xl p-8 md:p-0"
        >
          <h2 className="font-bold text-4xl md:text-6xl tracking-tight mb-8 md:mb-12 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
            {t('title')}
          </h2>
          <p className="text-xl md:text-2xl text-base-content/80 mb-12 md:mb-16 max-w-2xl">
            {t('subtitle')}
          </p>

          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="btn btn-primary btn-lg group px-8"
            >
              <span>{t('primaryButton')}</span>
              <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
            </motion.button>
            
            <motion.a
              href="#pricing"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="btn btn-outline btn-lg"
            >
              {t('secondaryButton')}
            </motion.a>
          </div>

          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="mt-8 text-base-content/60 text-sm"
          >
            {t('disclaimer')}
          </motion.p>
        </motion.div>
      </div>
    </section>
  );
};

export default CTA;
